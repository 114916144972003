import { SVGProps } from 'react';

const SvgFee = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 78 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_8547_88549)">
      <path
        d="M55.9376 47.0636L38.0314 42.8948C35.7646 42.4111 34.5728 39.9026 35.481 37.8055C36.4256 35.6245 39.0343 34.8615 40.9379 36.184L56.1919 46.4764C56.4058 46.6687 56.2242 47.0881 55.9376 47.0636Z"
        fill="#9597A2"
      />
      <g filter="url(#filter0_dd_8547_88549)">
        <ellipse cx="38.9034" cy="38.9015" rx="7.07335" ry="7.07335" fill="#F4F5FB" />
      </g>
      <path
        d="M74.1132 54.229L74.1928 54.0686C76.1829 49.2553 77.2178 44.1212 77.2178 38.9069C77.2178 17.4881 59.8638 0 38.6091 0C17.3544 0 0.00038147 17.4881 0.00038147 38.9069C0.00038147 44.2015 1.03526 49.4158 3.105 54.229L10.4287 51.1004C8.75699 47.2498 7.96094 43.1586 7.96094 38.9069C7.96094 21.9002 21.7327 8.02204 38.6091 8.02204C55.4855 8.02204 69.2572 21.9002 69.2572 38.9069C69.2572 43.0784 68.4612 47.0894 66.8691 50.94L66.7894 51.0202L74.1132 54.229Z"
        fill="url(#paint0_linear_8547_88549)"
      />
      <g clipPath="url(#clip1_8547_88549)">
        <path
          d="M15.6422 48.5344C15.9677 48.5344 16.2316 48.2705 16.2316 47.9449C16.2316 47.6194 15.9677 47.3555 15.6422 47.3555C15.3166 47.3555 15.0527 47.6194 15.0527 47.9449C15.0527 48.2705 15.3166 48.5344 15.6422 48.5344Z"
          fill="white"
        />
        <path
          d="M14.1461 40.1437C14.4716 40.1437 14.7355 39.8798 14.7355 39.5543C14.7355 39.2287 14.4716 38.9648 14.1461 38.9648C13.8205 38.9648 13.5566 39.2287 13.5566 39.5543C13.5566 39.8798 13.8205 40.1437 14.1461 40.1437Z"
          fill="white"
        />
        <path
          d="M15.5631 31.7531C15.8886 31.7531 16.1525 31.4892 16.1525 31.1637C16.1525 30.8381 15.8886 30.5742 15.5631 30.5742C15.2375 30.5742 14.9736 30.8381 14.9736 31.1637C14.9736 31.4892 15.2375 31.7531 15.5631 31.7531Z"
          fill="white"
        />
        <path
          d="M19.8131 24.382C20.1386 24.382 20.4025 24.1181 20.4025 23.7926C20.4025 23.467 20.1386 23.2031 19.8131 23.2031C19.4875 23.2031 19.2236 23.467 19.2236 23.7926C19.2236 24.1181 19.4875 24.382 19.8131 24.382Z"
          fill="white"
        />
        <path
          d="M26.3463 18.8937C26.6718 18.8937 26.9357 18.6298 26.9357 18.3043C26.9357 17.9787 26.6718 17.7148 26.3463 17.7148C26.0207 17.7148 25.7568 17.9787 25.7568 18.3043C25.7568 18.6298 26.0207 18.8937 26.3463 18.8937Z"
          fill="white"
        />
        <path
          d="M34.2975 15.9914C34.623 15.9914 34.8869 15.7275 34.8869 15.4019C34.8869 15.0764 34.623 14.8125 34.2975 14.8125C33.9719 14.8125 33.708 15.0764 33.708 15.4019C33.708 15.7275 33.9719 15.9914 34.2975 15.9914Z"
          fill="white"
        />
        <path
          d="M42.8766 15.9133C43.2021 15.9133 43.466 15.6494 43.466 15.3238C43.466 14.9983 43.2021 14.7344 42.8766 14.7344C42.551 14.7344 42.2871 14.9983 42.2871 15.3238C42.2871 15.6494 42.551 15.9133 42.8766 15.9133Z"
          fill="white"
        />
        <path
          d="M50.9059 18.8156C51.2314 18.8156 51.4953 18.5517 51.4953 18.2262C51.4953 17.9006 51.2314 17.6367 50.9059 17.6367C50.5803 17.6367 50.3164 17.9006 50.3164 18.2262C50.3164 18.5517 50.5803 18.8156 50.9059 18.8156Z"
          fill="white"
        />
        <path
          d="M57.4391 24.2258C57.7646 24.2258 58.0285 23.9619 58.0285 23.6363C58.0285 23.3108 57.7646 23.0469 57.4391 23.0469C57.1135 23.0469 56.8496 23.3108 56.8496 23.6363C56.8496 23.9619 57.1135 24.2258 57.4391 24.2258Z"
          fill="white"
        />
        <path
          d="M61.7682 31.5969C62.0937 31.5969 62.3576 31.333 62.3576 31.0074C62.3576 30.6819 62.0937 30.418 61.7682 30.418C61.4426 30.418 61.1787 30.6819 61.1787 31.0074C61.1787 31.333 61.4426 31.5969 61.7682 31.5969Z"
          fill="white"
        />
        <path
          d="M63.2642 39.9094C63.5898 39.9094 63.8537 39.6455 63.8537 39.3199C63.8537 38.9944 63.5898 38.7305 63.2642 38.7305C62.9387 38.7305 62.6748 38.9944 62.6748 39.3199C62.6748 39.6455 62.9387 39.9094 63.2642 39.9094Z"
          fill="white"
        />
        <path
          d="M61.8473 48.2961C62.1728 48.2961 62.4367 48.0322 62.4367 47.7066C62.4367 47.3811 62.1728 47.1172 61.8473 47.1172C61.5217 47.1172 61.2578 47.3811 61.2578 47.7066C61.2578 48.0322 61.5217 48.2961 61.8473 48.2961Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_8547_88549"
        x="28.8301"
        y="29.8281"
        width="20.1465"
        height="20.1484"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0313726 0 0 0 0 0.235294 0 0 0 0 0.580392 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8547_88549" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0313726 0 0 0 0 0.235294 0 0 0 0 0.580392 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_8547_88549"
          result="effect2_dropShadow_8547_88549"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_8547_88549"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_8547_88549"
        x1="77.2178"
        y1="27.1145"
        x2="0.00038147"
        y2="27.1145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EC008C" />
        <stop offset="1" stopColor="#FC6767" />
      </linearGradient>
      <clipPath id="clip0_8547_88549">
        <rect width="77.2174" height="54.229" fill="white" />
      </clipPath>
      <clipPath id="clip1_8547_88549">
        <rect
          width="50.6923"
          height="34.1879"
          fill="white"
          transform="translate(13.5566 14.7344)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgFee;
