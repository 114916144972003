import { SVGProps } from 'react';

const SvgCaretDoubleLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="17.7303"
      y="16.9934"
      width="16.7236"
      height="16.7236"
      rx="8.36181"
      transform="rotate(-180 17.7303 16.9934)"
      stroke="#82775E"
      strokeWidth="0.539472"
    />
    <path
      d="M8.57189 8.37462L12.208 4.73848C12.2763 4.67025 12.3688 4.63192 12.4653 4.63192C12.5618 4.63192 12.6543 4.67025 12.7225 4.73848C12.7908 4.80671 12.8291 4.89925 12.8291 4.99574C12.8291 5.09223 12.7908 5.18476 12.7225 5.25299L9.3432 8.63188L12.7225 12.0108C12.7908 12.079 12.8291 12.1715 12.8291 12.268C12.8291 12.3645 12.7908 12.457 12.7225 12.5253C12.6543 12.5935 12.5618 12.6318 12.4653 12.6318C12.3688 12.6318 12.2763 12.5935 12.208 12.5253L8.57189 8.88913C8.53808 8.85536 8.51126 8.81526 8.49296 8.77112C8.47466 8.72698 8.46524 8.67966 8.46524 8.63188C8.46524 8.58409 8.47466 8.53678 8.49296 8.49263C8.51126 8.44849 8.53808 8.40839 8.57189 8.37462ZM4.93574 8.88913L8.57189 12.5253C8.64012 12.5935 8.73265 12.6318 8.82914 12.6318C8.92563 12.6318 9.01817 12.5935 9.0864 12.5253C9.15463 12.457 9.19296 12.3645 9.19296 12.268C9.19296 12.1715 9.15463 12.079 9.0864 12.0108L5.70706 8.63188L9.0864 5.25299C9.15463 5.18476 9.19296 5.09223 9.19296 4.99574C9.19296 4.89925 9.15463 4.80671 9.0864 4.73848C9.01817 4.67025 8.92563 4.63192 8.82914 4.63192C8.73265 4.63192 8.64012 4.67025 8.57189 4.73848L4.93574 8.37462C4.90194 8.40839 4.87512 8.44849 4.85682 8.49263C4.83852 8.53678 4.8291 8.58409 4.8291 8.63188C4.8291 8.67966 4.83852 8.72698 4.85682 8.77112C4.87512 8.81526 4.90194 8.85536 4.93574 8.88913Z"
      fill="#82775E"
    />
  </svg>
);

export default SvgCaretDoubleLeft;
