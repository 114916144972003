import defaultAvatarSrc from 'assets/img/default-avatar.svg';
import { ButtonProps, Icon, QuaternaryButton } from 'components';
import { useTranslation } from 'packages/translations';
import { cn, truncateAddress } from 'utilities';

export interface PrimeButtonProps extends ButtonProps {
  accountAddress: string;
}

export const PrimeButton: React.FC<PrimeButtonProps> = ({
  accountAddress,
  className,
  ...otherProps
}) => {
  const { t } = useTranslation();

  return (
    <QuaternaryButton
      className={cn(
        'relative border-transparent px-2 py-[10px] hover:border-transparent active:border-transparent',
        className,
      )}
      contentClassName="flex items-center gap-2 text-base font-medium"
      {...otherProps}
    >
      <>
        <img
          className="h-5 w-5 shrink-0"
          src={defaultAvatarSrc}
          alt={t('PrimeButton.primeLogoAlt')}
        />

        {truncateAddress(accountAddress)}
        <Icon name="arrowDown" className="fill-brandYellow-40" />
      </>
    </QuaternaryButton>
  );
};
