/* hard coded address */
export default {
  VenusLens: {
    56: '0xfB0f09dB330dC842a6637BfB959209424BbFE8C7',
    97: '0x11c8dC3DcA87E8205ec01e6d79Be9442d31701d3',
    168587773: '0x89AcbF361E09245cc2290d225A047D9EaE5276E5',
    81457: '0x3AD461031984e9e88f3A4b2fa08D6eB41a0EAB81',
    31337: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
  },
  PoolLens: {
    1: '0x5A44D6dc0330A92674F9FAdE1e4eB40b3D2aDf04',
    56: '0x25E215CcE40bD849B7c286912B85212F984Ff1e0',
    97: '0x6492dF28A9478230205c940A245Ffb114EaEb9d1',
    5611: '0x8983fd85133877BE52c019ce9538354EFbb238c6',
    11155111: '0xC319C2E53a5f732ebF0eb87b14F56B53bdc3446b',
  },
  PoolRegistry: {
    1: '0x61CAff113CCaf05FFc6540302c37adcf077C5179',
    56: '0x9F7b01A536aFA00EF10310A162877fd792cD0666',
    97: '0xC85491616Fa949E048F3aAc39fbf5b0703800667',
    5611: '0x560eA4e1cC42591E9f5F5D83Ad2fd65F30128951',
    11155111: '0x758f5715d817e02857Ba40889251201A5aE3E186',
  },
  LegacyPoolComptroller: {
    56: '0xfD36E2c2a6789Db23113685031d7F16329158384',
    97: '0x94d1820b2D1c7c7452A163983Dc888CEC546b77D',
    168587773: '0xd99c00613e26E607B00690384BD5e277fFD2Fd3F',
    31337: '0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e',
    81457: '0x4EdF556c5664b4f86Ec50dB0F58B58B26210DC31',
  },
  VaiController: {
    56: '0x004065D34C6b18cE4370ced1CeBDE94865DbFAFE',
    97: '0xf70C3C6b749BbAb89C081737334E74C9aFD4BE16',
  },
  VaiVault: {
    56: '0x0667Eed0a0aAb930af74a3dfeDD263A73994f216',
    97: '0x7Db4f5cC3bBA3e12FF1F528D2e3417afb0a57118',
  },
  XvsVault: {
    1: '0xA0882C2D5DF29233A092d2887A258C2b90e9b994',
    56: '0x051100480289e704d20e9DB4804837068f3f9204',
    97: '0x9aB56bAD2D7631B2A857ccf36d998232A8b82280',
    5611: '0xB14A0e72C5C202139F78963C9e89252c1ad16f01',
    11155111: '0x1129f882eAa912aE6D4f6D445b2E2b1eCbA99fd5',
  },
  XvsStore: {
    1: '0x1Db646E1Ab05571AF99e47e8F909801e5C99d37B',
    56: '0x1e25CF968f12850003Db17E0Dba32108509C4359',
    97: '0x7B4dba3b320E7D421A213e1a73bB9858bb6839F8',
    5611: '0xB14A0e72C5C202139F78963C9e89252c1ad16f01',
    11155111: '0x03B868C7858F50900fecE4eBc851199e957b5d3D',
  },
  GovernorBravoDelegate: {
    56: '0x2d56dC077072B53571b8252008C60e945108c75a',
    97: '0x5573422A1a59385C247ec3a66B93B7C08eC2f8f2',
  },
  XvsVesting: {
    56: '0xb28Dec7C7Ac80f4D0B6a1B711c39e444cDE8B2cE',
    97: '0xcfa3a6bc934EcA22fa39e854c823cD3dE79D9BC8',
  },
  VrtConverter: {
    56: '0x92572fB60f4874d37917C53599cAe5b085B9Facd',
    97: '0xAb81e79F9607eC4C7b69Ab4f7cf6c0AF607aA131',
  },
  Maximillion: {
    56: '0x5efA1e46F4Fd738FF721F5AebC895b970F13E8A1',
    97: '0xF3a34e06015e019D6154a0f1089f695B27122f50',
  },
  XsequenceMulticall: {
    1: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
    56: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
    97: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
    5611: '0x34D4c29902769a0168b9965CbC4147771206328D',
    11155111: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
  },
  Multicall3: {
    56: '0xca11bde05977b3631167028862be2a173976ca11',
    97: '0xca11bde05977b3631167028862be2a173976ca11',
    5611: '0xca11bde05977b3631167028862be2a173976ca11',
    11155111: '0xca11bde05977b3631167028862be2a173976ca11',
  },
  ResilientOracle: {
    1: '0xd2ce3fb018805ef92b8C5976cb31F84b4E295F94',
    56: '0x6592b5DE802159F3E74B2486b091D11a8256ab8A',
    97: '0x3cD69251D04A28d887Ac14cbe2E14c52F3D57823',
    5611: '0xd2ce3fb018805ef92b8C5976cb31F84b4E295F94',
    11155111: '0x8000eca36201dddf5805Aa4BeFD73d1EB4D23264',
    // 168587773: '0x1a96E2DBe2a4609566a5f34E70E591b78f0d19c1',
    168587773: '0xb219E87Fd42DbD8e059ab8984F9eEe2B633bCD24',
    81457: '',
    31337: '0xc5a5C42992dECbae36851359345FE25997F5C42d',
  },
  Prime: {
    56: '0xBbCD063efE506c3D42a0Fa2dB5C08430288C71FC',
    97: '0xe840F8EC2Dc50E7D22e5e2991975b9F6e34b62Ad',
    11155111: '0x27A8ca2aFa10B9Bc1E57FC4Ca610d9020Aab3739',
  },
  VTreasury: {
    56: '0xF322942f644A996A617BD29c16bd7d231d9F35E9',
    97: '0x8b293600c50d6fbdc6ed4251cc75ece29880276f',
  },
  VTreasuryV8: {
    1: '0xFD9B071168bC27DBE16406eC3Aba050Ce8Eb22FA',
    5611: '0x3370915301E8a6A6baAe6f461af703e2498409F3',
    11155111: '0x4116CA92960dF77756aAAc3aFd91361dB657fbF8',
  },
  XVSProxyOFTDest: {
    1: '0x888E317606b4c590BBAD88653863e8B345702633',
    5611: '0xA03205bC635A772E533E7BE36b5701E331a70ea3',
    11155111: '0xc340b7d3406502F43dC11a988E4EC5bbE536E642',
  },
  XVSProxyOFTSrc: {
    56: '0xf8F46791E3dB29a029Ec6c9d946226f3c613e854',
    97: '0x0E132cd94fd70298b747d2b4D977db8d086e5fD0',
  },
  SwapRouter: {
    1: {},
    56: {
      '0xfd36e2c2a6789db23113685031d7f16329158384': '0x8938E6dA30b59c1E27d5f70a94688A89F7c815a4',
      '0x94c1495cd4c557f1560cbd68eab0d197e6291571': '0xBBd8E2b5d69fcE9Aaa599c50F0f0960AA58B32aA',
      '0x23b4404e4e5ec5ff5a6ffb70b7d14e3fabf237b0': '0xacD270Ed7DFd4466Bd931d84fe5B904080E28Bfc',
      '0x1b43ea8622e76627b81665b1ecebb4867566b963': '0x9B15462a79D0948BdDF679E0E5a9841C44aAFB7A',
      '0x3344417c9360b963ca93a4e8305361aede340ab9': '0x47bEe99BD8Cf5D8d7e815e2D2a3E2985CBCcC04b',
      '0xd933909a4a2b7a4638903028f44d1d38ce27c352': '0x5f0ce69Aa564468492e860e8083BB001e4eb8d56',
    },
    97: {
      '0x94d1820b2d1c7c7452a163983dc888cec546b77d': '0x83edf1deE1B730b7e8e13C00ba76027D63a51ac0',
      '0x10b57706ad2345e590c2ea4dc02faef0d9f5b08b': '0x8Ff3c0a74b4CBD4dFA3A35Cca756490bE351F936',
      '0x11537d023f489e4ef0c7157cc729c7b69cbe0c97': '0x1D8cA5AFB88F07489786A3d2E0FF50F3F9314d97',
      '0x1f4f0989c51f12dacacd4025018176711f3bf289': '0x5D254Bc7c7f2670395B9E0716C21249083D41a4f',
      '0x23a73971a6b9f6580c048b9cb188869b2a2aa2ad': '0x89Bc8dFe0Af08b60ec285071d133FCdfa9B3C08e',
      '0x596b11acaacf03217287939f88d63b51d3771704': '0xb16792E90d6478DaBbd0144e13f41CeA21ACE116',
    },
    5611: {},
    11155111: {},
    168587773: {},
    81457: {},
  },
};
