import { Token } from 'types';
import { cn } from 'utilities';

import { Icon, IconName } from '../Icon';
import { Tooltip } from '../Tooltip';

export interface LabeledInlineContentProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  tooltip?: string;
  children: React.ReactNode;
  invertTextColors?: boolean;
  iconSrc?: IconName | Token;
}

export const LabeledInlineContent = ({
  label,
  tooltip,
  iconSrc,
  invertTextColors = false,
  children,
  className,
  ...otherContainerProps
}: LabeledInlineContentProps) => (
  <div
    className={cn('flex w-full items-center justify-between space-x-4', className)}
    {...otherContainerProps}
  >
    <div className="flex items-center text-sm md:text-base">
      {typeof iconSrc === 'string' && <Icon name={iconSrc} className="-mt-[2px] mr-2 h-5 w-5" />}

      {/* {!!iconSrc && typeof iconSrc !== 'string' && (
        <TokenIcon token={iconSrc} className="-mt-[2px] mr-2 h-5 w-5" />
      )} */}

      <p className={cn('text-sm leading-5', invertTextColors ? 'text-black-30' : 'text-black-30')}>
        {label}
      </p>

      {!!tooltip && (
        <Tooltip className="ml-2 inline-flex items-center" title={tooltip}>
          <Icon className="w-3 cursor-help text-black-30" name="info" />
        </Tooltip>
      )}
    </div>

    <div
      className={cn(
        'flex items-center text-[13px] font-medium',
        invertTextColors ? 'text-white-default' : 'text-offWhite',
      )}
    >
      {children}
    </div>
  </div>
);
