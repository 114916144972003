/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Icon, Logo } from 'components';

import { ENV_VARIABLES } from 'config';
import ConnectButton from '../ConnectButton';

const DOC_URL = ENV_VARIABLES.VITE_DOC_URL;

export const useStyles = () => {
  const theme = useTheme();
  return {
    book: css`
      stroke: ${theme.palette.base.primary};
      width: 24px;
      height: 24px;
      cursor: pointer;
    `,
  };
};

export const Header: React.FC = () => {
  const styles = useStyles();
  return (
    <div className="flex justify-between gap-2 border-b border-black-80 px-4 py-5 sm:px-6 md:gap-8 md:px-8 xl:px-12">
      <div className="flex shrink-0 items-center">
        <Logo />
      </div>
      <div className="flex items-center justify-end gap-3 md:gap-4 lg:gap-6">
        <a href={DOC_URL} target="_blank" rel="noreferrer">
          <Icon name="book" css={styles.book} />
        </a>
        <ConnectButton className="inline-flex h-9 w-fit px-3 py-3 font-medium uppercase sm:px-5" />
      </div>
    </div>
  );
};
