export const theme = {
  fontFamily: {
    sans: ['ProximaNova', 'Arial', 'sans-serif'],
    neueMachina: ['NeueMachina', 'Arial', 'sans-serif'],
    neueHaas: ['NeueHaas', 'Arial', 'sans-serif'],
  },
  screens: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
    xxl: '1440px',
  },
  colors: {
    inherit: 'inherit',
    transparent: 'transparent',
    // The next colors are taken from the UI kit in Figma
    primary: '#FFBE19',
    darkerPrimary: '#664900',
    background: '#181D27',
    cards: '#1E2431',
    grey: '#AAB3CA',
    lightGrey: '#2D3549',
    green: '#00C38E',
    red: '#E93D66',
    offWhite: '#FFFFFF',
    lightWhite: '#DBDBDB',
    white: {
      default: '#DBDBDB',
    },
    orange: '#F57842',
    yellow: '#F5B242',
    blue: '#3A78FF',
    // The next colors are not in the UI kit, but are used throughout the designs
    mediumBlue: '#265ACC',
    darkBlue: '#1B4398',
    offGrey: '#8C8C8C',
    primaryBlack: '#333333',
    subdued: {
      40: '#FFE5BB',
    },
    'semantic-green': '#17E88D',
    black: {
      10: '#161616',
      20: '#B3B3B3',
      30: '#737373',
      50: '#4D4D4D',
      60: '#383838',
      80: '#2E2E2E',
      100: '#282828',
      default: '#1B1B1B',
    },
    brandYellow: {
      30: '#766B53',
      40: '#82775E',
      50: '#996E00',
    },
    brandGreen: {
      10: '#58A87D',
    },
  },
  fontSize: {
    xs: ['0.8rem', '1.5'],
    sm: ['0.985rem', '1.5'],
    base: ['1rem', '1.5'],
    lg: [
      '1.35rem',
      {
        lineHeight: '1.5',
        fontWeight: '600',
      },
    ],
    xl: [
      '1.5rem',
      {
        lineHeight: '1.5',
        fontWeight: '700',
      },
    ],
    '2xl': [
      '2rem',
      {
        lineHeight: '1.5',
        fontWeight: '600',
      },
    ],
    '3xl': [
      '2.5rem',
      {
        lineHeight: '1.2',
        fontWeight: '600',
      },
    ],
  },
  boxShadow: {
    DEFAULT: '0px 4px 15px 0px #0D1017',
    yellow: '10px 5px 50px 1px #ef4930;',
    red: '0px 0px 21.579px 0px #EF4930',
  },

  // We keep Tailwind's original sizing scale but make it more granular (with 0.25rem steps) and
  // extend it to bigger values
  spacing: new Array(200).fill(undefined).map((_, index) => `${index * 0.25}rem`),
  backgroundImage: {
    'hero-patters': "url('/images/bg.jpeg')",
    flowers: "url('/images/flowers.png')",
    'flowers-sm': "url('/images/flowers-sm.svg')",
    flower: "url('/images/flower.svg')",
    primaryGradient:
      'linear-gradient(180deg, rgba(180, 118, 110, 0.00) 0%, rgba(255, 190, 25, 0.00) 0.01%, rgba(255, 190, 25, 0.30) 100%)',
  },
  extends: {
    lineHeight: {
      120: '120%',
      130: '130%',
      140: '140%',
      142: '142%',
      150: '150%',
    },
  },
};
