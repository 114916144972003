import { store } from 'packages/wallet/store';

export const useAuthModal = () => {
  const isAuthModalOpen = store.use.isAuthModalOpen();
  const setIsAuthModalOpen = store.use.setIsAuthModalOpen();
  const openAuthModal = () => setIsAuthModalOpen({ isAuthModalOpen: true });
  const closeAuthModal = () => setIsAuthModalOpen({ isAuthModalOpen: false });
  const isSwitchNetworkModalOpen = store.use.isSwitchNetworkModalOpen();
  const setIsSwitchNetworkModalOpen = store.use.setIsSwitchNetworkModalOpen();
  const openSwitchNetworkModal = () =>
    setIsSwitchNetworkModalOpen({ isSwitchNetworkModalOpen: true });
  const closeSwitchNetworkModal = () =>
    setIsSwitchNetworkModalOpen({ isSwitchNetworkModalOpen: false });

  return {
    isAuthModalOpen,
    openAuthModal,
    closeAuthModal,
    isSwitchNetworkModalOpen,
    openSwitchNetworkModal,
    closeSwitchNetworkModal,
  };
};
