import { SVGProps } from 'react';

import { cn } from 'utilities';

const SvgCaretDoubleUp = ({ className, ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn('fill-brandYellow-40 stroke-brandYellow-40', className)}
    {...rest}
  >
    <rect
      x="1.00655"
      y="17.4662"
      width="16.7236"
      height="16.7236"
      rx="8.36181"
      transform="rotate(-90 1.00655 17.4662)"
      stroke="inherit"
      fill="none"
      strokeWidth="0.539472"
    />
    <path
      d="M9.62538 8.30782L13.2615 11.944C13.3298 12.0122 13.3681 12.1047 13.3681 12.2012C13.3681 12.2977 13.3298 12.3902 13.2615 12.4585C13.1933 12.5267 13.1008 12.565 13.0043 12.565C12.9078 12.565 12.8152 12.5267 12.747 12.4585L9.36812 9.07913L5.98924 12.4585C5.92101 12.5267 5.82847 12.565 5.73198 12.565C5.63549 12.565 5.54295 12.5267 5.47472 12.4585C5.40649 12.3902 5.36816 12.2977 5.36816 12.2012C5.36816 12.1047 5.40649 12.0122 5.47472 11.944L9.11086 8.30782C9.14464 8.27401 9.18474 8.24719 9.22888 8.22889C9.27302 8.21059 9.32034 8.20118 9.36812 8.20118C9.41591 8.20118 9.46322 8.21059 9.50736 8.22889C9.55151 8.24719 9.59161 8.27401 9.62538 8.30782ZM9.11086 4.67168L5.47472 8.30782C5.40649 8.37605 5.36816 8.46859 5.36816 8.56507C5.36816 8.66157 5.40649 8.7541 5.47472 8.82233C5.54295 8.89056 5.63549 8.92889 5.73198 8.92889C5.82847 8.92889 5.92101 8.89056 5.98924 8.82233L9.36812 5.44299L12.747 8.82233C12.8152 8.89056 12.9078 8.92889 13.0043 8.92889C13.1008 8.92889 13.1933 8.89056 13.2615 8.82233C13.3298 8.7541 13.3681 8.66157 13.3681 8.56508C13.3681 8.46859 13.3298 8.37605 13.2615 8.30782L9.62538 4.67168C9.59161 4.63787 9.55151 4.61105 9.50736 4.59275C9.46322 4.57445 9.41591 4.56503 9.36812 4.56503C9.32034 4.56503 9.27302 4.57445 9.22888 4.59275C9.18474 4.61105 9.14464 4.63787 9.11086 4.67168Z"
      fill="inherit"
    />
  </svg>
);

export default SvgCaretDoubleUp;
