import { EvmPriceServiceConnection } from '@pythnetwork/pyth-evm-js';
import * as ethers from 'ethers';
import { useMemo } from 'react';

import { useProvider } from 'packages/wallet';

const connection = new EvmPriceServiceConnection('https://hermes.pyth.network');

const fetchLatestPriceFeedsData = async ({ priceIds }: { priceIds: string[] }) => {
  try {
    return await connection.getLatestPriceFeeds(priceIds);
  } catch (error) {
    console.log({ error });
    return [];
  }
};

export const fetchPriceFeedUpdateData = async ({ priceIds }: { priceIds: string[] }) => {
  try {
    return await connection.getPriceFeedsUpdateData(priceIds);
  } catch (error) {
    return [];
  }
};

export const usePriceFeedData = ({ priceIds }: { priceIds: string[] }) =>
  useMemo(async () => {
    if (priceIds.length) {
      return fetchLatestPriceFeedsData({
        priceIds,
      });
    }

    return [];
  }, [priceIds]);

export const usePriceFeedUpdateData = ({ priceIds }: { priceIds: string[] }) =>
  useMemo(async () => {
    if (priceIds.length) {
      return fetchPriceFeedUpdateData({
        priceIds,
      });
    }

    return [];
  }, [priceIds]);

export const useUpdateFee = async ({
  priceFeedUpdateData,
  address,
  abi,
}: {
  priceFeedUpdateData: string[];
  address: any;
  abi: any;
}) => {
  const provider: any = useProvider();
  const contract = new ethers.Contract(address, abi, provider);
  return contract.methods.getUpdateFee(priceFeedUpdateData).call();
};
