import { SVGProps } from 'react';

const SvgCoinStacked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      d="M12.0634 4.91215C12.0634 5.90529 9.84939 6.71039 7.11825 6.71039C4.38712 6.71039 2.1731 5.90529 2.1731 4.91215M12.0634 4.91215C12.0634 3.91901 9.84939 3.11391 7.11825 3.11391C4.38712 3.11391 2.1731 3.91901 2.1731 4.91215M12.0634 4.91215V8.91966C10.9651 9.24948 10.2652 9.74845 10.2652 10.3069M2.1731 4.91215V15.7016C2.1731 16.6947 4.38712 17.4998 7.11825 17.4998C8.31375 17.4998 9.41016 17.3456 10.2652 17.0888V10.3069M2.1731 8.50862C2.1731 9.50177 4.38712 10.3069 7.11825 10.3069C8.31375 10.3069 9.41016 10.1526 10.2652 9.89583M2.1731 12.1051C2.1731 13.0982 4.38712 13.9033 7.11825 13.9033C8.31375 13.9033 9.41016 13.7491 10.2652 13.4923M20.1555 10.3069C20.1555 11.3 17.9415 12.1051 15.2103 12.1051C12.4792 12.1051 10.2652 11.3 10.2652 10.3069M20.1555 10.3069C20.1555 9.31372 17.9415 8.50862 15.2103 8.50862C12.4792 8.50862 10.2652 9.31372 10.2652 10.3069M20.1555 10.3069V17.4998C20.1555 18.493 17.9415 19.2981 15.2103 19.2981C12.4792 19.2981 10.2652 18.493 10.2652 17.4998V10.3069M20.1555 13.9033C20.1555 14.8965 17.9415 15.7016 15.2103 15.7016C12.4792 15.7016 10.2652 14.8965 10.2652 13.9033"
      stroke="#6B727E"
      strokeWidth="1.07894"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCoinStacked;
