import { SVGProps } from 'react';

const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M11.5031 7.29196H5.92327C4.96203 7.29196 4.48064 8.45414 5.16034 9.13383L7.95026 11.9238C8.37161 12.3451 9.05476 12.3451 9.47611 11.9238L12.266 9.13383C12.9457 8.45414 12.4643 7.29196 11.5031 7.29196Z"
      fill="#82775E"
    />
  </svg>
);

export default SvgArrowDown;
