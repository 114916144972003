import { create } from 'zustand';

import { createStoreSelectors } from 'utilities';

interface State {
  isAuthModalOpen: boolean;
  setIsAuthModalOpen: (input: { isAuthModalOpen: boolean }) => void;
  isSwitchNetworkModalOpen: boolean;
  setIsSwitchNetworkModalOpen: (input: { isSwitchNetworkModalOpen: boolean }) => void;
}

const useStore = create<State>()(set => ({
  isAuthModalOpen: false,
  setIsAuthModalOpen: ({ isAuthModalOpen }) => set({ isAuthModalOpen }),
  isSwitchNetworkModalOpen: false,
  setIsSwitchNetworkModalOpen: ({ isSwitchNetworkModalOpen }) => set({ isSwitchNetworkModalOpen }),
}));

export const store = createStoreSelectors(useStore);
