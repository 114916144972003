import { SVGProps } from 'react';

const SvgCaretDoubleRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1.00655"
      y="0.269736"
      width="16.7236"
      height="16.7236"
      rx="8.36181"
      stroke="#82775E"
      strokeWidth="0.539472"
    />
    <path
      d="M10.1649 8.8885L6.52879 12.5246C6.46056 12.5929 6.36802 12.6312 6.27153 12.6312C6.17504 12.6312 6.0825 12.5929 6.01427 12.5246C5.94605 12.4564 5.90771 12.3639 5.90771 12.2674C5.90771 12.1709 5.94605 12.0784 6.01427 12.0101L9.39361 8.63124L6.01427 5.25236C5.94605 5.18413 5.90771 5.09159 5.90771 4.9951C5.90771 4.89861 5.94605 4.80607 6.01427 4.73785C6.0825 4.66962 6.17504 4.63129 6.27153 4.63129C6.36802 4.63129 6.46056 4.66962 6.52879 4.73785L10.1649 8.37399C10.1987 8.40776 10.2256 8.44786 10.2439 8.492C10.2622 8.53614 10.2716 8.58346 10.2716 8.63124C10.2716 8.67903 10.2622 8.72635 10.2439 8.77049C10.2256 8.81463 10.1987 8.85473 10.1649 8.8885ZM13.8011 8.37399L10.1649 4.73785C10.0967 4.66962 10.0042 4.63129 9.90767 4.63129C9.81118 4.63129 9.71864 4.66962 9.65042 4.73785C9.58219 4.80607 9.54386 4.89861 9.54386 4.9951C9.54386 5.09159 9.58219 5.18413 9.65042 5.25236L13.0298 8.63124L9.65042 12.0101C9.58219 12.0784 9.54386 12.1709 9.54386 12.2674C9.54386 12.3639 9.58219 12.4564 9.65042 12.5246C9.71864 12.5929 9.81118 12.6312 9.90767 12.6312C10.0042 12.6312 10.0967 12.5929 10.1649 12.5246L13.8011 8.8885C13.8349 8.85473 13.8617 8.81463 13.88 8.77049C13.8983 8.72635 13.9077 8.67903 13.9077 8.63124C13.9077 8.58346 13.8983 8.53614 13.88 8.492C13.8617 8.44786 13.8349 8.40776 13.8011 8.37399Z"
      fill="#82775E"
    />
  </svg>
);

export default SvgCaretDoubleRight;
