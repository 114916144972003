import { useDisconnect } from 'wagmi';

import { Modal } from 'components/Modal';
import { CHAIN_METADATA } from 'constants/chainMetadata';
import { chains, useSwitchChain } from 'packages/wallet';
import { useAccountAddress } from 'packages/wallet/hooks/useAccountAddress';
import { useAuthModal } from 'packages/wallet/hooks/useAuthModal';
import { ChainId } from 'types';
import { cn } from 'utilities';

export const SwitchNetworkModal: React.FC = () => {
  const { isSwitchNetworkModalOpen, closeSwitchNetworkModal } = useAuthModal();
  const { disconnectAsync } = useDisconnect();
  const { accountAddress } = useAccountAddress();
  const { switchChain } = useSwitchChain();
  const handleClose = (_?: any, reason?: string) => {
    if (reason && ['backdropClick', 'escapeKeyDown'].includes(reason)) return;
    disconnectAsync();
    closeSwitchNetworkModal();
  };

  const renderContent = () =>
    chains.map(chain => {
      const metadata = CHAIN_METADATA[chain.id as ChainId];

      return (
        <div
          key={metadata.name}
          className="flex min-w-full cursor-pointer items-center justify-between rounded-md bg-black-100 px-3 py-3 text-left text-sm font-semibold hover:bg-lightGrey active:bg-lightGrey"
          onClick={() => switchChain({ chainId: Number(chain.id) })}
        >
          <img src={metadata.logoSrc} alt={metadata.name} className="w-5 max-w-none flex-none" />

          <span className={cn('ml-2 grow overflow-hidden text-ellipsis')}>{metadata.name}</span>
        </div>
      );
    });

  return (
    <Modal
      className="blume-modal"
      isOpen={isSwitchNetworkModalOpen}
      handleClose={handleClose}
      noHorizontalPadding={!accountAddress}
      title={<h4>Switch Network</h4>}
    >
      <div className="space-y-4">
        <p>Select Supported Network</p>
        <div className="space-y-4">{renderContent()}</div>
      </div>
    </Modal>
  );
};
