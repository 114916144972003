import { SVGProps } from 'react';

const SvgArrowClockwise = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    {...props}
  >
    <path
      d="M21.0501 5.61467V9.66071C21.0501 9.83955 20.9791 10.0111 20.8526 10.1375C20.7262 10.264 20.5546 10.335 20.3758 10.335H16.3297C16.1509 10.335 15.9794 10.264 15.8529 10.1375C15.7265 10.0111 15.6554 9.83955 15.6554 9.66071C15.6554 9.48186 15.7265 9.31034 15.8529 9.18388C15.9794 9.05741 16.1509 8.98637 16.3297 8.98637H18.6394L16.398 6.933L16.3769 6.91277C15.4397 5.97585 14.2471 5.33588 12.9482 5.07279C11.6494 4.8097 10.3017 4.93517 9.07376 5.43352C7.84578 5.93187 6.79185 6.78101 6.04365 7.87485C5.29545 8.9687 4.88615 10.2588 4.86687 11.5839C4.84759 12.909 5.21919 14.2104 5.93525 15.3256C6.65131 16.4407 7.68008 17.3201 8.89305 17.854C10.106 18.3879 11.4494 18.5525 12.7554 18.3273C14.0614 18.1021 15.272 17.4971 16.2362 16.5879C16.3662 16.4649 16.5397 16.3986 16.7186 16.4036C16.8974 16.4086 17.067 16.4844 17.1899 16.6144C17.3129 16.7444 17.3792 16.9179 17.3742 17.0968C17.3692 17.2757 17.2934 17.4452 17.1634 17.5682C15.6642 18.99 13.6755 19.7804 11.6094 19.7758H11.4981C10.1728 19.7577 8.87225 19.4142 7.71076 18.7757C6.54926 18.1373 5.56238 17.2232 4.83685 16.114C4.11133 15.0048 3.66937 13.7344 3.54983 12.4143C3.43028 11.0943 3.63681 9.76514 4.15126 8.54363C4.66571 7.32212 5.47233 6.24567 6.50022 5.40891C7.52812 4.57214 8.74582 4.00067 10.0463 3.74472C11.3468 3.48877 12.6902 3.55618 13.9585 3.94103C15.2268 4.32588 16.3812 5.01638 17.3202 5.95184L19.7014 8.12658V5.61467C19.7014 5.43582 19.7725 5.2643 19.899 5.13784C20.0254 5.01137 20.1969 4.94033 20.3758 4.94033C20.5546 4.94033 20.7262 5.01137 20.8526 5.13784C20.9791 5.2643 21.0501 5.43582 21.0501 5.61467Z"
      fill="#737373"
    />
  </svg>
);

export default SvgArrowClockwise;
