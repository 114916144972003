import { SVGProps } from 'react';

const SvgSwitchVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M12.6989 3.79044V15.2992M12.6989 15.2992L9.82168 12.422M12.6989 15.2992L15.576 12.422M5.50591 15.2992V3.79044M5.50591 3.79044L2.62872 6.66762M5.50591 3.79044L8.38309 6.66762"
      stroke="white"
      strokeWidth="1.07894"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgSwitchVertical;
