import BigNumber from 'bignumber.js';

import { PrimeApy } from 'types';
import { extractSettledPromiseValue } from 'utilities';

import { formatToPool } from './formatToPool';
import { GetLegacyPoolInput, GetLegacyPoolOutput } from './types';

export type { GetLegacyPoolInput, GetLegacyPoolOutput } from './types';

const getLegacyPool = async ({
  blocksPerDay,
  name,
  description,
  tokens,
  accountAddress,
  legacyPoolComptrollerContract,
  venusLensContract,
  // vaiControllerContract,
  // resilientOracleContract,
  // primeContract,
  priceFeeds,
}: GetLegacyPoolInput): Promise<GetLegacyPoolOutput> => {
  const [marketsResult, assetsInResult] = await Promise.allSettled([
    // Fetch all markets
    legacyPoolComptrollerContract.getAllMarkets(),
    accountAddress ? legacyPoolComptrollerContract.getAssetsIn(accountAddress) : undefined,
  ]);

  if (marketsResult.status === 'rejected') {
    throw new Error(marketsResult.reason);
  }

  const vTokenAddresses = marketsResult.value;

  // Fetch underlying token prices
  // const underlyingTokenPricePromises = Promise.allSettled(
  //   vTokenAddresses.map(vTokenAddress =>
  //     resilientOracleContract?.getUnderlyingPrice(vTokenAddress),
  //   ),
  // );

  // Fetch vToken borrow and deposit caps
  const borrowCapsPromises = Promise.allSettled(
    vTokenAddresses.map(vTokenAddress => legacyPoolComptrollerContract.borrowCaps(vTokenAddress)),
  );
  const supplyCapsPromises = Promise.allSettled(
    vTokenAddresses.map(vTokenAddress => legacyPoolComptrollerContract.supplyCaps(vTokenAddress)),
  );

  // Fetch vToken meta data and user balance
  const vTokenMetaDataPromises = Promise.allSettled([
    // Fetch vToken data
    venusLensContract.callStatic.vTokenMetadataAll(vTokenAddresses),
    // Fetch user vToken balances
    accountAddress
      ? venusLensContract.callStatic.vTokenBalancesAll(vTokenAddresses, accountAddress)
      : undefined,
  ]);

  // const underlyingTokenPriceResults = await underlyingTokenPricePromises;
  const underlyingTokenPriceFeeds = await priceFeeds;

  const borrowCapsResults = await borrowCapsPromises;
  const supplyCapsResults = await supplyCapsPromises;
  // const xvsBorrowSpeedResults = await xvsBorrowSpeedPromises;
  // const xvsSupplySpeedResults = await xvsSupplySpeedPromises;
  const [vTokenMetaDataResults, userVTokenBalancesResults] = await vTokenMetaDataPromises;
  // const primeAprResults = (await primeAprPromises) || [];

  if (vTokenMetaDataResults.status === 'rejected') {
    throw new Error(vTokenMetaDataResults.reason);
  }

  const primeApyMap = new Map<string, PrimeApy>();

  const pool = formatToPool({
    blocksPerDay,
    name,
    xvs: undefined,
    vai: undefined,
    tokens,
    description,
    comptrollerContractAddress: legacyPoolComptrollerContract.address,
    vTokenMetaDataResults: vTokenMetaDataResults.value,
    // underlyingTokenPriceResults,
    underlyingTokenPriceFeeds,
    borrowCapsResults,
    supplyCapsResults,
    xvsBorrowSpeedResults: [],
    xvsSupplySpeedResults: [],
    xvsPriceMantissa: new BigNumber('0'),
    userCollateralizedVTokenAddresses: extractSettledPromiseValue(assetsInResult),
    userVTokenBalances: extractSettledPromiseValue(userVTokenBalancesResults),
    userVaiBorrowBalanceMantissa: undefined,
    primeApyMap,
    mainMarkets: undefined,
  });

  return {
    pool,
  };
};

export default getLegacyPool;
