import usdbLogo from 'packages/tokens/img/usdb.svg';
import wethLogo from 'packages/tokens/img/weth.svg';
import { Token } from 'types';

// 168587773
export const tokens: Token[] = [
  {
    address: '0x322813Fd9A801c5507c9de605d63CEA4f2CE6c44',
    decimals: 18,
    priceId: '',
    symbol: 'WETH',
    asset: wethLogo,
  },
  {
    address: '0x4ed7c70F96B99c776995fB64377f0d4aB3B0e1C1',
    decimals: 18,
    priceId: '',
    symbol: 'USDB',
    asset: usdbLogo,
  },
];
