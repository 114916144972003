import { useGetPrimeToken } from 'clients/api';
import { ButtonProps, GoldenButton } from 'components';
import { useTranslation } from 'packages/translations';
import { useAccountAddress, useAuthModal } from 'packages/wallet';
import { truncateAddress } from 'utilities';

import { PrimeButton } from './PrimeButton';

export interface ConnectButtonUiProps extends ButtonProps {
  isAccountPrime: boolean;
  accountAddress?: string;
}

export const ConnectButtonUi: React.FC<ConnectButtonUiProps> = ({
  accountAddress,
  isAccountPrime,
  loading,
  ...otherProps
}) => {
  const { t } = useTranslation();

  if (loading) {
    return null;
  }

  if (accountAddress) {
    return (
      <PrimeButton
        {...otherProps}
        className="h-9 border-brandYellow-50/20 bg-brandYellow-50/10 text-brandYellow-40 hover:bg-brandYellow-40/20"
        accountAddress={accountAddress}
      />
    );
  }

  return (
    <GoldenButton {...otherProps}>
      {accountAddress ? <>{truncateAddress(accountAddress)}</> : t('connectButton.title')}
    </GoldenButton>
  );
};

export const ConnectButton: React.FC<
  Omit<ConnectButtonUiProps, 'isAccountPrime' | 'accountAddress' | 'loading'>
> = props => {
  const { accountAddress } = useAccountAddress();
  const { openAuthModal } = useAuthModal();

  const { data: getPrimeTokenData, isLoading: isGetPrimeTokenLoading } = useGetPrimeToken({
    accountAddress,
  });
  const isAccountPrime = !!getPrimeTokenData?.exists;

  return (
    <ConnectButtonUi
      accountAddress={accountAddress}
      isAccountPrime={isAccountPrime}
      loading={isGetPrimeTokenLoading}
      onClick={openAuthModal}
      {...props}
    />
  );
};

export default ConnectButton;
