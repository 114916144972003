import { SVGProps } from 'react';

const SvgCaretDoubleDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="17.73"
      y="0.374777"
      width="16.7236"
      height="16.7236"
      rx="8.36181"
      transform="rotate(90 17.73 0.374777)"
      stroke="#82775E"
      strokeWidth="0.539472"
    />
    <path
      d="M9.11119 9.53312L5.47505 5.89698C5.40682 5.82875 5.36849 5.73622 5.36849 5.63973C5.36849 5.54324 5.40682 5.4507 5.47505 5.38247C5.54328 5.31424 5.63582 5.27591 5.73231 5.27591C5.8288 5.27591 5.92134 5.31424 5.98957 5.38247L9.36845 8.76181L12.7473 5.38247C12.8156 5.31424 12.9081 5.27591 13.0046 5.27591C13.1011 5.27591 13.1936 5.31424 13.2618 5.38247C13.3301 5.4507 13.3684 5.54324 13.3684 5.63973C13.3684 5.73622 13.3301 5.82875 13.2618 5.89698L9.62571 9.53312C9.59194 9.56693 9.55183 9.59375 9.50769 9.61205C9.46355 9.63035 9.41623 9.63977 9.36845 9.63977C9.32067 9.63977 9.27335 9.63035 9.22921 9.61205C9.18507 9.59375 9.14496 9.56693 9.11119 9.53312ZM9.62571 13.1693L13.2618 9.53312C13.3301 9.4649 13.3684 9.37236 13.3684 9.27587C13.3684 9.17938 13.3301 9.08684 13.2618 9.01861C13.1936 8.95038 13.1011 8.91205 13.0046 8.91205C12.9081 8.91205 12.8156 8.95038 12.7473 9.01861L9.36845 12.3979L5.98957 9.01861C5.92134 8.95038 5.8288 8.91205 5.73231 8.91205C5.63582 8.91205 5.54328 8.95038 5.47505 9.01861C5.40682 9.08684 5.36849 9.17938 5.36849 9.27587C5.36849 9.37236 5.40682 9.4649 5.47505 9.53312L9.11119 13.1693C9.14496 13.2031 9.18507 13.2299 9.22921 13.2482C9.27335 13.2665 9.32067 13.2759 9.36845 13.2759C9.41623 13.2759 9.46355 13.2665 9.50769 13.2482C9.55183 13.2299 9.59194 13.2031 9.62571 13.1693Z"
      fill="#82775E"
    />
  </svg>
);

export default SvgCaretDoubleDown;
