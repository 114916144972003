import usdbLogo from 'packages/tokens/img/usdb.svg';
import wethLogo from 'packages/tokens/img/weth.svg';
import { Token } from 'types';

// 168587773
export const tokens: Token[] = [
  {
    address: '0x4300000000000000000000000000000000000004',
    priceId: '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
    // priceId: '0x9d4294bbcd1174d6f2003ec365831e64cc31d9f6f15a2b85399db8d5000960f6',
    decimals: 18,
    symbol: 'WETH',
    asset: wethLogo,
    defaultPriceMantissa: 355099988002,
  },
  {
    address: '0x4300000000000000000000000000000000000003',
    // priceId: '0x41283d3f78ccb459a24e5f1f1b9f5a72a415a26ff9ce0391a6878f4cda6b477b',
    priceId: '',
    decimals: 18,
    symbol: 'USDB',
    asset: usdbLogo,
    defaultPriceMantissa: 99988002,
  },
];
