import { type Chain } from 'viem';

import { ChainId } from 'types';

export const blastMainnet = {
  id: ChainId.BLAST_MAINNET,
  name: 'Blast Mainnet',
  network: 'blast-mainnet',
  nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: [
        'https://floral-soft-uranium.blast-mainnet.quiknode.pro/500b499beff25a3d2bce141b40e7694068d4334f',
      ],
    },
    public: {
      http: [
        'https://floral-soft-uranium.blast-mainnet.quiknode.pro/500b499beff25a3d2bce141b40e7694068d4334f',
      ],
    },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://blastscan.io' },
  },
  contracts: undefined,
  testnet: false,
} as const satisfies Chain;

export const blastTestnet = {
  id: ChainId.BLAST_TESTNET,
  name: 'Blast Testnet',
  network: 'blast-testnet',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://sepolia.blast.io'] },
    public: { http: ['https://sepolia.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://testnet.blastscan.io' },
  },
  contracts: undefined,
  testnet: true,
} as const satisfies Chain;

export const localhost = {
  id: ChainId.LOCALHOST,
  name: 'localhost',
  network: 'localhost',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['http://127.0.0.1:8545'] },
    public: { http: ['http://127.0.0.1:8545'] },
  },
  blockExplorers: {
    default: { name: 'localhostblastscan', url: 'https://testnet.blastscan.io' },
  },
  contracts: undefined,
  testnet: true,
} as const satisfies Chain;
