import { type Chain } from 'wagmi/chains';

import localConfig from 'config';

import { blastMainnet, blastTestnet, localhost } from './customChains';

const getSupportedChains = (): Chain[] => {
  if (localConfig.isOnTestnet) {
    // return [blastTestnet, bscTestnet, sepolia];
    return [blastTestnet, localhost];
  }

  return [blastMainnet];
};

export const governanceChain = localConfig.isOnTestnet ? blastTestnet : blastMainnet;

// export const governanceChain = blastTestnet;

export const chains = getSupportedChains();

export const defaultChain = chains[0];
