import { useMemo } from 'react';
import { QueryObserverOptions, useQuery } from 'react-query';

import getLegacyPool, {
  GetLegacyPoolInput,
  GetLegacyPoolOutput,
} from 'clients/api/queries/getLegacyPool';
import FunctionKey from 'constants/functionKey';
import { useGetChainMetadata } from 'hooks/useGetChainMetadata';
import { usePriceFeedData } from 'hooks/usePythNetwork';
import { useGetLegacyPoolComptrollerContract, useGetVenusLensContract } from 'packages/contracts';
import { useGetTokens } from 'packages/tokens';
import { useTranslation } from 'packages/translations';
import { useChainId } from 'packages/wallet';
import { ChainId } from 'types';
import { callOrThrow, generatePseudoRandomRefetchInterval } from 'utilities';

type TrimmedInput = Omit<
  GetLegacyPoolInput,
  | 'blocksPerDay'
  | 'provider'
  | 'name'
  | 'description'
  | 'venusLensContract'
  | 'legacyPoolComptrollerContract'
  | 'resilientOracleContract'
  | 'vaiControllerContract'
  | 'vai'
  | 'xvs'
  | 'tokens'
>;

export type UseGetLegacyPoolQueryKey = [
  FunctionKey.GET_LEGACY_POOL,
  TrimmedInput & { chainId: ChainId },
  additionalQueryKey?: 'PrimeCalculator',
];

type Options = QueryObserverOptions<
  GetLegacyPoolOutput,
  Error,
  GetLegacyPoolOutput,
  GetLegacyPoolOutput,
  UseGetLegacyPoolQueryKey
>;

const refetchInterval = generatePseudoRandomRefetchInterval();

const useGetLegacyPool = (input?: TrimmedInput, options?: Options) => {
  const { chainId } = useChainId();
  const { blocksPerDay } = useGetChainMetadata();

  const { t } = useTranslation();

  const tokens = useGetTokens();

  const legacyPoolComptrollerContract = useGetLegacyPoolComptrollerContract();
  const venusLensContract = useGetVenusLensContract();
  // const resilientOracleContract = useGetResilientOracleContract();
  // const vaiControllerContract = useGetVaiControllerContract();
  // const primeContract = useGetPrimeContract();

  const priceIds = useMemo(
    () => tokens.filter(token => token.priceId).map(token => token.priceId),
    [tokens],
  );

  const priceFeeds = usePriceFeedData({
    priceIds,
  });

  const isQueryEnabled =
    !!legacyPoolComptrollerContract &&
    !!venusLensContract &&
    (options?.enabled === undefined || options?.enabled);

  return useQuery(
    [FunctionKey.GET_LEGACY_POOL, { ...input, chainId }],
    () =>
      callOrThrow(
        {
          legacyPoolComptrollerContract,
          venusLensContract,
          // resilientOracleContract,
          priceFeeds,
        },
        params =>
          getLegacyPool({
            blocksPerDay,
            name: t('legacyPool.name'),
            description: t('legacyPool.description'),
            tokens,
            primeContract: undefined,
            ...input,
            ...params,
          }),
      ),
    {
      refetchInterval,
      ...options,
      enabled: isQueryEnabled,
    },
  );
};

export default useGetLegacyPool;
