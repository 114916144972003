/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { cn } from 'utilities';

export const useStyles = () => ({
  button: css`
    background: linear-gradient(
      180deg,
      rgba(180, 118, 110, 0) 0%,
      rgba(255, 190, 25, 0) 0.01%,
      rgba(255, 190, 25, 0.3) 100%
    );
    &:hover {
      background: #ffc633;
    }
    &:active {
      background: #664900;
    }
    &:disabled {
      background: #333333;
    }
  `,
});

interface GoldenButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  loading?: boolean;
}

export const GoldenButton: React.FC<GoldenButtonProps> = ({
  className = '',
  type = 'button',
  children,
  loading = false,
  ...props
}) => {
  const styles = useStyles();
  return (
    <button
      css={styles.button}
      type={type}
      {...props}
      className={cn(
        'inline-flex h-[41px] w-[215px] cursor-pointer select-none items-center justify-center rounded-full border border-primary px-5 py-[15px] text-center font-neueMachina text-[15px] font-medium font-medium leading-tight text-primary hover:from-primary hover:to-primary hover:text-darkerPrimary active:text-primary disabled:border-offGrey disabled:text-offGrey',
        className,
      )}
    >
      {children}
    </button>
  );
};
