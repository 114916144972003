import { SVGProps } from 'react';

const SvgThreeDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    {...props}
  >
    <rect x="0.626465" y="0.239319" width="25.8946" height="25.8946" rx="5.17893" fill="#4D4D4D" />
    <path
      d="M14.7876 13.1867C14.7876 13.4268 14.7164 13.6614 14.583 13.861C14.4496 14.0606 14.2601 14.2162 14.0383 14.3081C13.8165 14.4 13.5724 14.424 13.337 14.3772C13.1015 14.3303 12.8852 14.2147 12.7155 14.045C12.5457 13.8752 12.4301 13.6589 12.3833 13.4235C12.3364 13.188 12.3605 12.944 12.4524 12.7222C12.5442 12.5004 12.6998 12.3108 12.8994 12.1774C13.099 12.0441 13.3337 11.9729 13.5738 11.9729C13.8957 11.9729 14.2044 12.1008 14.4321 12.3284C14.6597 12.556 14.7876 12.8648 14.7876 13.1867ZM20.452 11.9729C20.212 11.9729 19.9773 12.0441 19.7777 12.1774C19.5781 12.3108 19.4225 12.5004 19.3306 12.7222C19.2387 12.944 19.2147 13.188 19.2615 13.4235C19.3084 13.6589 19.424 13.8752 19.5937 14.045C19.7635 14.2147 19.9798 14.3303 20.2152 14.3772C20.4507 14.424 20.6947 14.4 20.9165 14.3081C21.1383 14.2162 21.3279 14.0606 21.4613 13.861C21.5947 13.6614 21.6658 13.4268 21.6658 13.1867C21.6658 12.8648 21.538 12.556 21.3103 12.3284C21.0827 12.1008 20.774 11.9729 20.452 11.9729ZM6.6955 11.9729C6.45543 11.9729 6.22075 12.0441 6.02114 12.1774C5.82153 12.3108 5.66596 12.5004 5.57409 12.7222C5.48222 12.944 5.45818 13.188 5.50501 13.4235C5.55185 13.6589 5.66745 13.8752 5.83721 14.045C6.00696 14.2147 6.22324 14.3303 6.4587 14.3772C6.69416 14.424 6.93821 14.4 7.16001 14.3081C7.3818 14.2162 7.57137 14.0606 7.70475 13.861C7.83812 13.6614 7.90931 13.4268 7.90931 13.1867C7.90931 12.8648 7.78143 12.556 7.5538 12.3284C7.32616 12.1008 7.01742 11.9729 6.6955 11.9729Z"
      fill="#DBDBDB"
    />
  </svg>
);

export default SvgThreeDots;
