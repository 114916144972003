import usdbLogo from 'packages/tokens/img/usdb.svg';
import wethLogo from 'packages/tokens/img/weth.svg';
import { Token } from 'types';

// 168587773
export const tokens: Token[] = [
  {
    address: '0x4200000000000000000000000000000000000023',
    priceId: '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
    decimals: 18,
    symbol: 'WETH',
    asset: wethLogo,
    defaultPriceMantissa: 280199988002,
  },
  {
    address: '0x4200000000000000000000000000000000000022',
    priceId: '0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a',
    decimals: 18,
    symbol: 'USDB',
    asset: usdbLogo,
    defaultPriceMantissa: 99988002,
  },
];
