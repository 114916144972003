import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    getLabel: ({ hasError }: { hasError: boolean }) => css`
      display: block;
      margin-bottom: ${theme.spacing(1)};
      color: ${hasError ? theme.palette.error.main : theme.palette.text.secondary};
    `,
    getInputContainer: ({
      hasError,
      disabled,
      variant,
      isSmall,
    }: {
      hasError: boolean;
      disabled: boolean | undefined;
      variant: 'primary' | 'secondary';
      isSmall: boolean;
    }) => {
      let borderColor = theme.palette.black['80'];
      let backgroundColor = variant === 'primary' ? 'transparent' : theme.palette.background.paper;

      if (hasError) {
        borderColor = theme.palette.interactive.error;
      }

      if (disabled) {
        borderColor = theme.palette.secondary.light;
        backgroundColor =
          variant === 'primary' ? theme.palette.black['80'] : theme.palette.secondary.light;
      }

      return css`
        display: flex;
        align-items: center;
        padding: ${isSmall ? theme.spacing(1, 2, 1, 4) : theme.spacing(4)};
        border-radius: ${theme.spacing(1)};
        border: 1px solid ${borderColor};
        transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: ${backgroundColor};

        ${isSmall &&
        css`
          height: ${theme.spacing(10)};
        `}

        ${!disabled &&
        css`
          &:hover {
            border-color: ${theme.palette.black[50]};
          }
        `}

        &:focus-within {
          border-color: ${hasError ? theme.palette.interactive.error : theme.palette.base.primary};
        }
      `;
    },
    getLeftIcon: ({ isSmall }: { isSmall: boolean }) => css`
      margin-right: ${theme.spacing(2)};
      margin-top: 0;
      width: ${theme.spacing(isSmall ? 5 : 6)};
      height: ${theme.spacing(isSmall ? 5 : 6)};
    `,
    getInput: ({
      hasRightAdornment,
      isSmall,
    }: {
      hasRightAdornment: boolean;
      isSmall: boolean;
    }) => css`
      background-color: transparent;
      flex: 1;
      font-weight: 500;
      line-height: ${theme.spacing(7)};
      font-size: ${theme.spacing(6)};
      padding-top: 2px; /* Vertically align input content */
      border: 0;
      width: 100%;
      height: ${theme.spacing(isSmall ? 6 : 10)};

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme.palette.text.secondary};
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.palette.text.secondary};
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.palette.text.secondary};
      }

      ${isSmall &&
      css`
        font-size: ${theme.typography.small2.fontSize};
      `}

      ${hasRightAdornment &&
      css`
        margin-right: ${theme.spacing(1)};
      `};

      &:focus {
        outline: 0;
      }
    `,
    rightButton: css`
      margin-right: ${theme.spacing(2)};
    `,
    description: css`
      display: block;
      color: ${theme.palette.text.secondary};
      margin-top: ${theme.spacing(1)};
    `,
  };
};
